// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-taggar-index-tsx": () => import("./../../../src/pages/taggar/index.tsx" /* webpackChunkName: "component---src-pages-taggar-index-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-cookies-page-tsx": () => import("./../../../src/templates/cookies-page.tsx" /* webpackChunkName: "component---src-templates-cookies-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-pressimages-page-tsx": () => import("./../../../src/templates/pressimages-page.tsx" /* webpackChunkName: "component---src-templates-pressimages-page-tsx" */),
  "component---src-templates-published-page-tsx": () => import("./../../../src/templates/published-page.tsx" /* webpackChunkName: "component---src-templates-published-page-tsx" */),
  "component---src-templates-taggar-tsx": () => import("./../../../src/templates/taggar.tsx" /* webpackChunkName: "component---src-templates-taggar-tsx" */),
  "component---src-templates-website-page-tsx": () => import("./../../../src/templates/website-page.tsx" /* webpackChunkName: "component---src-templates-website-page-tsx" */)
}

