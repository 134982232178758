module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-indicator/gatsby-browser.js'),
      options: {"plugins":[],"color":"#C53030","paths":["/","/20*/**","/om","/publicerat"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"linkImagesToOriginal":false,"showCaptions":["title"],"withWebp":true},
    },{
      plugin: require('../node_modules/@raae/gatsby-remark-oembed/gatsby-browser.js'),
      options: {"plugins":[],"usePrefix":["youtube","vimeo","soundcloud"],"providers":{"include":["YouTube","Vimeo","SoundCloud"],"exclude":["Reddit","Instagram","Flickr","Twitter","Spotify"]},"settings":{"Instagram":{"hidecaption":true}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/typography","omitGoogleFont":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Isabel Sommerfelds personliga sajt","short_name":"Isabel","start_url":"/","background_color":"#2D3748","theme_color":"#2D3748","display":"browser","icon":"static/img/favimage.jpg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"69dc8a6e3e0b416ab717bfe77573f82d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js","manualInit":true,"enableIdentityWidget":true,"htmlTitle":"Redaktörsläge - Isabels sajt","htmlFavicon":"/opt/build/repo/static/img/edit-filled.ico"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
